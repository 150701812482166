import React from 'react'
import { MDXTag } from '@mdx-js/tag'




export default ({components, ...props}) => <MDXTag name="wrapper"  components={components}><MDXTag name="p" components={components}>{`The area known as “Hamilton’s Forum” was most recently excavated in the 1990’s by the Soprintendenza di Roma. Although very little has been published since this excavation, the Soprintendenza team asserts that they re-discovered the area first discovered by the Scottish antiquarian Gavin Hamilton in the late 18th century. Hamilton, who was more of an art dealer than an archaeologist, was drawn to the site of Gabii by the still-visible ruins of the Temple of Juno in the hopes of finding antiquities to sell. Fortunately for Hamilton, he came upon over 200 pieces of sculpture, a couple dozen of which were mostly intact full scale portraits. In addition to these sculptures, Hamilton describes a “publik space” in which the sculptures were situated; this was later illustrated by Ennio Q. Visconti in his work, monumenti gabini (fig 1). `}</MDXTag>
<MDXTag name="p" components={components}>{`Although it is not possible to determine whether this space was ever truly a forum, recent research conducted by the Louvre and the University of Michigan has revealed that the sculptures discovered by Hamilton most likely originated from multiple places, not just this public space. Certain pieces, such as the seated Hercules for example (fig 2), most likely came from a domestic context. Other pieces, however, such as the Julio-Claudian group, most likely did come from this space, thus indicating its possible relation to the imperial cult.`}</MDXTag>
<MDXTag name="p" components={components}><MDXTag name="img" components={components} parentName="p" props={{"src":"/assets/1559301226697-91e4dca7-f13c-4c78-8a6a-c81e1f5815fb.jpg","alt":null,"title":"Plan of the forum drawn by Ennio Q. Visconti "}}></MDXTag></MDXTag></MDXTag>

export const _frontmatter = {"templateKey":"content-pages","title":"Hamilton's Forum","backgroundImage":"","key":"hamiltons-forum","parentKey":"previous-explorations"};

  